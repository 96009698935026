@import '~antd/dist/antd.css';

.search_container {
  margin: 40px 0 20px;
}

.table_title {
  font-size: 18px;
  font-weight: 600;
}

.table_container {
  position: relative;
}

.table_right {
  position: absolute;
  right: 26px;
  top: 12px;
}

.table_count {
  font-size: 26px;
  font-weight: 600;
  margin-bottom: 2px;
  text-align: right;
}

.title_count_text {
  text-align: right;
}

.container_title {
  font-size: 18px;
  font-weight: 600;
  margin: 10px 10px;
}

.text_area_number {
  position: absolute;
  width: 100px;
  right: 10px;
  bottom: 10px;
  text-align: right;
}

.comment {
  display: inline-block;
  max-width: 120px;
  padding-right: 10px;
  overflow: hidden; /*内容超出后隐藏*/
  text-overflow: ellipsis; /* 超出内容显示为省略号*/
  white-space: nowrap; /*文本不进行换行*/
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}

.king_normal_input {
  border: none !important;
  border-bottom: 1px solid rgba(190, 197, 211, 1) !important;
  border-radius: 0 !important;
}

.king_normal_input:hover {
  border-bottom: 1px solid rgba(62, 113, 223, 1) !important;
}

.king_normal_input:focus {
  box-shadow: none !important;
  border-bottom: 1px solid rgba(62, 113, 223, 1) !important;
}

.modal-cover .ant-modal-content {
  background-color: rgba(0, 0, 0, 0);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0);
}

.modal-cover-body .ant-modal-content .ant-modal-body {
  padding: 0;
  border-radius: 12px;
}

.modal-cover .ant-modal-content .ant-modal-body {
  background-color: rgb(255, 255, 255);
}

.hand {
  cursor: pointer;
}

.linear-gradient-gray {
  background: linear-gradient(135deg, #69707a 100%, #8d949e 0%) !important;
  box-shadow: 2px 2px 5px 0px #6b7a90;
  border: none !important;
}

.linear-gradient-golden {
  background: linear-gradient(
    15deg,
    rgba(222, 186, 108, 1) 0%,
    rgba(238, 205, 134, 1) 100%
  ) !important;
  box-shadow: 2px 3px 10px 0px rgba(107, 122, 144, 0.38);
  border: none !important;
}

.form_right {
  position: absolute;
  right: 6px;
  bottom: -12px;
}

.normal_button {
  width: 220px;
  height: 55px !important;
}

.header_img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.header_normal_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

.header_img_card {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.header_img_container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  line-height: 40px;
}

.ant-steps-dot .ant-steps-item-content {
  width: 230px !important;
}



.golden_container {
  /* padding: 10px 60px; */
  background: linear-gradient(
    79deg,
    rgba(222, 187, 110, 1) 0%,
    rgba(255, 224, 158, 1) 100%
  );
  min-height: 100vh;
  min-width: 100vw;
  padding-top: 2vh;
  padding-bottom: 2vh;
  overflow: auto;
  /* padding-top: 30px; */
}

.golden_content {
  background-image: url('./bg@2x.png');
  background-size: 100% 100%;
  max-width: 360px;
  /* max-height: 550px; */
  /* height: 500px; */
  min-height: 96vh;
  margin: 0 auto;
  text-align: center;
  padding: 30px;
}