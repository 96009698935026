.hello {
  font-size: 18px;
  font-family: Futura-Medium;
  font-weight: 500;
  color: rgba(222, 187, 110, 1);
  padding-top: 48px;
  letter-spacing: 4px;
}

.golden {
  color: #debb6e;
}

.paragraph {
  font-size: 34px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(105, 112, 122, 1);
  margin-top: 8px;
}

.big_paragraph {
  font-size: 24px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(105, 112, 122, 1);
  margin-top: 12px;
}

.warn_container {
  padding: 10px 60px;
  text-align: center;
}

.send_code {
  position: absolute;
  right: 8px;
  top: 48px;
  color: #deba6c;
}

.send_code_container {
  position: relative;
}

.sure {
  margin: 0 auto;
  width: 220px;
  height: 55px;
  display: block;
  font-size: 18px;
  margin-top: 40px;
}

.aggress {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
}

.modal_title {
  font-size: 24px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  text-align: center;
  border-bottom: rgba(216, 216, 216, 1) solid 1px;
  padding: 30px;
  width: 100%;
}

.modal_body {
  padding: 0px 40px 20px;
  height: 500px;
  overflow: auto;
  border-bottom: rgba(216, 216, 216, 1) solid 1px;
  margin-top: 20px;
}

.aggress_sure {
  width: 220px;
  height: 55px;
}

.button_group {
  padding: 20px;
  text-align: center;
}

.warn_img {
  width: 112px;
  height: 139px;
  margin-top: 120px;
}

.warn_title {
  font-size: 14px;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  margin-top: 30px;
}

.warn_desc {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(174, 177, 181, 1);
}

.invite {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(174, 177, 181, 1);
  margin-top: 46px;
}

.bottom {
  margin-top: 78px;
}

.bottom_title {
  font-size: 16px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(105, 112, 122, 1);
  margin-bottom: 12px;
}

.bottom_point {
  width: 4px;
  height: 4px;
  background: rgba(222, 187, 110, 1);
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  margin-bottom: 2px;
}

.bottom_list_item {
  font-size: 12px;
  font-family: PingFangSC-Regular;
  font-weight: 400;
  color: rgba(174, 177, 181, 1);
  line-height: 28px;
  text-align: left;
  width: 50vw;
  margin: 0 auto;
}

.share_title {
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  line-height: 14px;
  margin-top: 30px;
}

.share_desc {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(174, 177, 181, 1);
  line-height: 18px;
  margin-top: 12px;
}

.share_img {
  width: 90px;
  height: 90px;
  margin-top: 22px;
}

.share_top {
  font-size: 24px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(105, 112, 122, 1);
  line-height: 33px;
}
